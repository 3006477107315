<template>
<div>
  <search-field title="条件筛选">
    <el-form :inline="true" size="small" label-width="100px">
      <el-form-item label="门店名称" >
        <el-input clearable v-model="search.searchFields.shop_name" placeholder="请输入门店名称"></el-input>
      </el-form-item>
      <el-form-item label="门店编号" >
        <el-input clearable v-model="search.searchFields.shop_number" placeholder="请输入门店编号"></el-input>
      </el-form-item>
    </el-form>
    <div class="search-button-container">
      <el-button type="primary" size="small" @click="getShopList(1)">查询</el-button>
      <el-button type="primary" size="small"  @click="clearSearch()">重置</el-button>
    </div>
  </search-field>
  <div class="page-content">
    <div class="button-field">
      <el-button type="primary" size="small" @click="handleAdd()">添加</el-button>
    </div>
    <v-table
      :data="options.data"
      :columns="options.columns"
      :isBackPage="true"
      :totalCount="options.totalCount"
      :defaultcurrentPage="search.searchFields.page"
      @handleCurrentChange="handlePageChange"
      @handleSizeChange="handleSizeChange"
      @buttonClick="handleButtonClick"
    >
    </v-table>
  </div>
  <el-dialog
    title="新增"
    width="25%"
    :visible.sync="shopAddDialog"
    >
    <el-form size="small" label-width="110px">
      <el-form-item
        label="门店编号"
        required
        :error="errorBags.collect('门店编号')[0]"
      >
        <el-input
          v-model="shopForm.shop_number"
          placeholder="请输入门店编号"
          v-validate="'required'"
          data-vv-name="门店编号"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="门店名称"
        required
        :error="errorBags.collect('门店名称')[0]"
      >
        <el-input
          type="tel"
          v-model="shopForm.shop_name"
          maxlength="12"
          placeholder="请输入门店名称"
          v-validate="'required'"
          data-vv-name="门店名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="所在地"
        required
        :error="errorBags.collect('所在地')[0]"
      >
        <el-col :span="11">
          <el-select v-model="shopForm.province_id" @change="onGetCity(shopForm.province_id, true)">
            <el-option
              v-for="item in address.provinceOptions"
              :key="item.province_code"
              :value="item.id"
              :label="item.province_name"
              v-validate="'required'"
              data-vv-name="所在省"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>
        <el-col :span="12">
          <el-select v-model="shopForm.city_id" @change="onGetDistrict(shopForm.city_id, true)">
            <el-option
              v-for="item in address.cityOptions"
              :key="item.city_code"
              :value="item.id"
              :label="item.city_name"
              v-validate="'required'"
              data-vv-name="所在城市"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item
        label="所在地区"
        required
        :error="errorBags.collect('所在地区')[0]"
      >
        <el-select v-model="shopForm.district_id">
          <el-option
            v-for="item in address.districtOptions"
            :key="item.district_code"
            :value="item.id"
            :label="item.district_name"
            v-validate="'required'"
            data-vv-name="所在区县"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="详细地址"
        required
        :error="errorBags.collect('详细地址')[0]"
      >
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="shopForm.detail_address"
          data-vv-name="详细地址"
          v-validate="'required'"
          placeholder="请输入详细地址"
          maxlength="150"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="BA经理">
        <el-select clearable v-model="shopForm.shop_belong_manage_id" placeholder="请选择">
          <el-option
            v-for="item in BAList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="btn-container">
        <el-button @click="closeModal">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-form>
  </el-dialog>
</div>
</template>
<script>

import vTable from '@/components/tools/vtable'
import searchField from '@/components/tools/searchField'
import { BAApi } from '@/api'

export default {
  data() {
    return {
      options: {
        columns: [
          {
            label: '门店名称',
            key: 'shop_name'
          },
          {
            label: '门店编号',
            key: 'shop_number'
          },
          {
            label: 'BA经理',
            key: 'ba_manage_name'
          },
          {
            label: '地址',
            type: 'render',
            render: (h, { row }) => {
              return (
                <div>
                  {row.province_name + row.city_name + row.district_name + row.detail_address}
                </div>
              )
            }
          },
          {
            label: '操作',
            type: 'action',
            buttonInfos: [
              {
                name: 'edit',
                label: '编辑',
                color: 'primary'
              }
            ]
          }
        ],
        data: [],
        totalCount: 0,
        loading: false,
      },
      search: {
        searchFields: {
          page: 1,
          count: 20,
          shop_name: '',
          shop_number: '',
        }
      },
      shopAddDialog: false,
      shopForm: {
        shop_id: '',
        shop_number: '',
        shop_name: '',
        province_id: '',
        city_id: '',
        district_id: '',
        detail_address: '',
        shop_belong_manage_id: ''
      },
      address: {
        provinceOptions: [],
        cityOptions: [],
        districtOptions: []
      },
      BAList:[]
    }
  },
  methods: {
    // 后端分页
    handlePageChange(page) {
      this.search.searchFields.page = page
      this.getShopList()
    },
    handleSizeChange(count) {
      this.search.searchFields.count = count
      this.getShopList(1)
    },
    async getShopList(page) {
      let loading = this.$loading()
      this.search.searchFields.page = page || this.search.searchFields.page
      try {
        let data = (await BAApi.getShopList(this.search.searchFields)).data
        if (data.code === 0) {
          this.$validator.reset()
          console.log(data);
          this.options.data = data.shop_list
          this.options.totalCount = parseInt(data.total_count) || 0
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    async getBAList(page) {
      let loading = this.$loading()
      let params = {
        role_id: 3,
        count: 1000
      }
      try {
        let data = (await BAApi.getEmployeeList(params)).data
        if (data.code === 0) {
          this.BAList = data.data.employee
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    clearSearch() {
      this.search = {
        searchFields: Object.assign({ count: this.search.searchFields.count }, {
          page: 1,
          shop_name: '',
          shop_number: '',
        })
      }
    },
    async handleButtonClick(value) {
      let type = value.button,
        data = value.data;
        this.getShop(data.id)
      if (type === 'edit') {
        this.disabled = false
      } else {
        this.disabled = true
      }
      this.shopAddDialog = true
    },
    async getShop(id) {
      try {
        let data = (await BAApi.getShop(id)).data
        if (data.code === 0) {
          this.shopForm = Object.assign(this.shopForm, data.shop_detail)
          if (!this.address.provinceOptions.length) {
            this.getOptions()
          }
          if(this.shopForm.province_id) {
            this.onGetCity(this.shopForm.province_id)
          }
          if(this.shopForm.city_id) {
            this.onGetDistrict(this.shopForm.city_id)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取省
    async getOptions() {
      try {
        let data = (await BAApi.getAddressProvinceList()).data
        if (data.code === 0) {
          this.address.provinceOptions = data.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取市
    async onGetCity(province_id, clearCity) {
      try {
        let data = (
          await BAApi.getAddressCityList({
            province_id,
          })
        ).data
        if (data.code === 0) {
          this.address.cityOptions = data.data
          if(clearCity) {
            this.shopForm.city_id = ''
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取区县
    async onGetDistrict(city_id, clearDistrict) {
      try {
        let data = (
          await BAApi.getAddressDistrictList({
            city_id
          })
        ).data
        if (data.code === 0) {
          this.address.districtOptions = data.data
          if(clearDistrict) {
            this.shopForm.district_id = ''
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    closeModal() {
      this.resetForm()
      this.shopAddDialog = false
    },
    handleSubmit() {
      this.$validator.validate().then(status => {
        if (status) {
          status =
            status &&
            !!this.shopForm.province_id &&
            !!this.shopForm.city_id &&
            !!this.shopForm.district_id
            // !!this.shopForm.shop_belong_manage_id
          if (status) {
            this.addShop()
          } else {
            this.$message.error('请正确填写数据')
          }
        } else {
          this.$message.error('请正确填写数据')
        }
      })
    },
    async addShop() {
      let loading = this.$loading()
      try {
        let data = (await BAApi.addOrEditShop(this.shopForm)).data
        if (data.code === 0) {
          loading.close()
          this.$validator.reset()
          this.$message.success('添加门店成功')
          this.resetForm()
          this.shopAddDialog = false
          this.getShopList()
        } else {
          loading.close()
          this.$error(data)
        }
      } catch (error) {
        this.$validator.reset()
        loading.close()
      }
    },
    resetForm() {
      let obj = {
        shop_id: '',
        shop_number: '',
        shop_name: '',
        province_id: '',
        city_id: '',
        district_id: '',
        detail_address: '',
        shop_belong_manage_id: ''
      }
      this.shopForm = obj
      this.address.cityOptions = []
      this.address.districtOptions = []
    },
    handleAdd() {
      this.resetForm()
      this.$validator.reset()
      this.shopAddDialog = true
      if (!this.address.provinceOptions.length) {
        this.getOptions()
      }
    }
  },

  mounted() {
    this.getShopList()
    this.getBAList()
  },
  components: {
    vTable, searchField
  }
}
</script>

<style lang="less">
.status-td {
  display: flex;
  align-items: center;
  justify-content: center;
  .tag {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .tag2 {
    background-color: #67C23A;
  }
  .tag3 {
    background-color: #F56C6C;
  }
}
</style>
